<template>
    <div class="flex flex-col py-7 items-center">
        <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
            <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
                <img src="../assets/necesidades-especiales/necesidades-especiales.webp" alt="necesidades"
                    class="h-64 w-full object-cover rounded-l-2xl">
            </div>
            <div
                class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800  justify-center items-center rounded-r-2xl">
                <p
                    class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-3 sm:px-0 text-center">
                    Necesidades especiales
                </p>
            </div>
        </div>
        <div>
            <!-- Pasamos la lista de infoCards como prop -->
            <CardsNecesidades :infoCards="cardsData" />
        </div>
    </div>
</template>

<script>
import CardsNecesidades from './CardsNecesidades.vue';

export default {
    name: 'NecesidadesEspeciales',
    components: {
        CardsNecesidades,
    },
    data() {
        return {
            cardsData: [
                {
                    title: "ADULTO MAYOR (SENIOR) CD.",
                    content: 'Se considera adulto mayor a pasajeros mayores de <strong>60 años.</strong>',
                    image: require('../assets/necesidades-especiales/adulto-mayor.webp'),
                    // route: '/adulto-mayor'
                },
                {
                    title: "PASAJERAS EN GESTACIÓN",
                    content: `Se permite pasajeras en gestación hasta los 7 meses de embarazo, formulario Pasajera en Gestación. Pasando el octavo mes de gestación se solicitará certificado médico junto con el formulario de deslinde de responsabilidad de la empresa. <ul class="list-disc pl-4"><li>Debe estar acompañada por un médico que asume toda la responsabilidad.</li><li>Debe llenar y firmar el formulario de Pasajera en Gestación.</li></ul>`,
                    image: require('../assets/necesidades-especiales/pasajeras-gestacion.webp'),
                    // route: '/gestacion'
               
                },
                {
                    title: "POST PARTO",
                    content: "Si el parto fue hace menos de siete días, no se aceptará su transporte, de acuerdo a recomendaciones de médicos autorizados en la materia.",
                    image: require('../assets/necesidades-especiales/post-parto.webp') // Usando require,
                    
                },
                {
                    title: "PASAJEROS CON DISCAPACIDAD VISUAL",
                    content: "Pasajeros con limitación severa o ausencia total en la vista, que ocasiona dificultades importantes para mirar, para orientarse, para ubicar el entorno y sus características. También acompañado con su perro lazarillo.",
                    image: require('../assets/necesidades-especiales/disca-visual.webp') // Usando require
                },
                {
                    title: "PASAJEROS CON DISCAPACIDAD AUDITIVA",
                    content: "Pasajeros con discapacidad auditiva que poseen pérdida moderada, severa o profunda de la audición.",
                    image: require('../assets/necesidades-especiales/disca-auditiva.webp') // Usando require
                },
                {
                    title: "PASAJEROS CON DISCAPACIDAD INTELECTUAL",
                    content: "Pasajeros que se caracterizan por limitaciones significativas en el funcionamiento intelectual y la conducta adaptativa, habilidades prácticas, sociales, conceptuales. Ej. Alzheimer, autismo, síndrome de Down o similares.",
                    image: require('../assets/necesidades-especiales/disca-intelectual.webp') // Usando require
                },
                {
                    title: "PASAJEROS CON MOVILIDAD REDUCIDA",
                    content: "Pasajero que puede necesitar asistencia de otra persona para dirigirse de un lugar a otro. Puede ser ambulatorios o no y requieren asistencia en silla de ruedas.",
                    image: require('../assets/necesidades-especiales/movilidad.webp') // Usando require
                }
            ]
        }
    }
}
</script>